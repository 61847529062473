/**
* Toggle overlay
*/
class Overlay {
  static init() {
    const buttons = document.querySelectorAll('.js-toggle-overlay');

    [].forEach.call(buttons, function(button) {
      button.addEventListener('click', () => {
        document.querySelector('.js-overlay').classList.toggle('o-overlay--open');
      });
    });
  }
}

export default Overlay;
