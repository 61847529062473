class Utilities {

  // set cookie
  static setCookie(name, value, days) {
    const d = new Date();
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
    document.cookie = `${name}=${value};path=/;expires=${d.toGMTString()}`;
  }

  // get cookie
  static getCookie(name) {
    const cookieName = name;
    const re = new RegExp(`${cookieName}=([^;]+)`);
    const value = re.exec(document.cookie);

    return (value != null) ? unescape(value[1]) : null;
  }
}

export default Utilities;
